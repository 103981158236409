<template>
  <span class="material-icons">{{ name }}</span>
</template>
<script>
export default {
  props: ["name"],
  components: {
    // Layout,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.material-icons {
  cursor: pointer;
  transition: 0.2s;
  color: var(--color-40);
}
.material-icons:hover {
  color: #0068ff;
}
</style>
